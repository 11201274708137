// @flow strict-local
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios, { CancelToken, type Canceler, AxiosPromise } from 'axios';
import * as R from 'ramda';
import { useLocation, useParams } from 'react-router-dom';
import type { CallForwardingStateEntityT } from '../../../ducks/entities/callForwarding';
import { retrieveCallForwarding } from '../../../ducks/entities/callForwarding/callForwardingOperations';
import { callForwardingById } from '../../../ducks/entities/callForwarding/callForwardingSelectors';
import { getAllForwardingTargets } from '../../../ducks/entities/directory/directoryOperations';
import type {
  ForwardingActionT,
  CallForwardingTypeT
} from '../../../ducks/entities/callForwarding/callForwardingTypes';
import type { CurrentUserT } from '../../../ducks/currentUser/currentUserTypes';

export type RetrievedContactsT = {|
  id: string,
  displayName: string,
  addressNumber: string,
  title: string
|}[];

export type ForwardingResponseT = {|
  forwarding: ?CallForwardingStateEntityT,
  visualForwardingType: CallForwardingTypeT,
  isForwardingLoading: boolean,
  destinationName: string,
  UUID: string,
  retrievedContacts: ?RetrievedContactsT
|};

const requestDestinationsCancelTokenSource = CancelToken.source();

const useForwarding = (
  enterpriseId: string,
  forwardingId: string,
  action: ForwardingActionT
): ForwardingResponseT => {
  const { forwardingType } = useParams();
  const location = useLocation();
  const cancelForwardingRequest = React.useRef<Canceler>();
  // redux
  const dispatch = useDispatch();
  const forwarding = useSelector(state => callForwardingById(state, forwardingId));
  const forwardingToCopy = R.path(['state', 'callForwarding'], location);
  const [destinationName, setDestinationName] = useState<string>('');
  const [isForwardingLoading, setIsForwardingLoading] = useState(true);
  const [UUID, setUUID] = useState<string>('');
  const [retrievedContacts, setRetrievedContacts] = useState<?RetrievedContactsT>(null);
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const getVisualForwardingTypeWhenUpdating = (returnedForwarding: CallForwardingStateEntityT) => {
    if (
      returnedForwarding.presenceStateFilter &&
      returnedForwarding.presenceStateFilter.length > 0
    ) {
      return 'PRESENCE';
    }
    return returnedForwarding.forwardingType;
  };

  const getVisualForwardingType = (fwd: ?CallForwardingStateEntityT): CallForwardingTypeT => {
    let returnedForwarding: ?CallForwardingStateEntityT = fwd;
    if (forwardingToCopy) {
      returnedForwarding = forwardingToCopy;
    }

    if (returnedForwarding && returnedForwarding.id) {
      return getVisualForwardingTypeWhenUpdating(returnedForwarding);
    }
    // $FlowFixMe forwardingType-type should be always valid
    return forwardingType || 'NO_ANSWER';
  };

  const visualForwardingType: CallForwardingTypeT = getVisualForwardingType(forwarding);

  const fetchForwarding = async () => {
    let fwd: ?CallForwardingStateEntityT = forwardingToCopy;
    if (!forwardingToCopy) {
      fwd = await dispatch(
        retrieveCallForwarding(
          enterpriseId || '',
          forwardingId || '',
          new CancelToken(cancelFn => {
            cancelForwardingRequest.current = cancelFn;
          })
        )
      );
    }
    const results = await dispatch(
      getAllForwardingTargets(enterpriseId, requestDestinationsCancelTokenSource.token, {})
    );
    const foundDestination =
      results &&
      fwd &&
      fwd.destination &&
      // $FlowFixMe: null check done above
      results.find(result => result.addressNumber === fwd.destination.value);
    if (foundDestination) {
      setDestinationName(`${foundDestination.label} (${foundDestination.addressNumber})`);
    }
    setIsForwardingLoading(false);
  };

  const retrieveContactsWithForwardingByLabel = async label => {
    if (((currentUser && currentUser.featureFlags) || []).includes('FEATURE-GENERIC-API')) {
      const response: AxiosPromise = axios({
        method: 'GET',
        // $FlowFixMe: enterpriseId already null checked
        url: `/api/v2/enterprises/${enterpriseId}/forwarding?label=${label}`
      });
      const { data } = await response;
      if (data) {
        setRetrievedContacts(
          data.results.map(result => ({
            id: result.id,
            displayName: result.displayName,
            forwardingId: result.forwardingID,
            publicInfo: {
              addressNumber: result.addressNumber,
              title: result.title
            }
          }))
        );
      }
    } else {
      const response: AxiosPromise = axios({
        method: 'GET',
        // $FlowFixMe: enterpriseId already null checked
        url: `/api/v1/enterprises/${enterpriseId}/forwarding?label=${label}`
      });
      const { data } = await response;
      setUUID(data);
    }
  };

  useEffect(() => {
    if (!R.isNil(enterpriseId) && !R.isNil(forwardingId)) {
      fetchForwarding();
    }
    if (action === 'group' && forwardingToCopy) {
      retrieveContactsWithForwardingByLabel(forwardingToCopy.label);
    }
    return () => cancelForwardingRequest.current && cancelForwardingRequest.current();
  }, [enterpriseId, forwardingId]); // eslint-disable-line react-hooks/exhaustive-deps
  return {
    forwarding: forwardingToCopy || forwarding,
    visualForwardingType,
    isForwardingLoading,
    destinationName,
    UUID,
    retrievedContacts
  };
};

export default useForwarding;

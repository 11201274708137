// @flow strict-local

import React, { type Element, useEffect, useState } from 'react';
import Input from '@design-system/component-library/src/components/Input';
import { useDispatch } from 'react-redux';
import Dropdown from '@design-system/component-library/src/components/Dropdown';
import { CancelToken } from 'axios';
import { useTranslation } from 'react-i18next';
import Search from '@design-system/component-library/src/components/Search';
import IconSearchRegular from '@design-system/component-library/src/components/Icon/lib/IconSearchRegular';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import type { DirectoryFwdT } from '../../ducks/entities/directory/directoryOperations';
import { getAllForwardingTargets } from '../../ducks/entities/directory/directoryOperations';
import type {
  CallForwardingDestinationT,
  CallForwardingT
} from '../../ducks/entities/callForwarding/callForwardingTypes';
import CenterHorizontally from '../CenterHorizontally/CenterHorizontally';
import fieldValidators from '../../fieldValidators';
import styles from './PresenceCallForwarding.module.scss';

type OwnPropsT = {|
  enterpriseId: string,
  forwardingTarget: CallForwardingDestinationT,
  updateForwardingTarget: CallForwardingDestinationT => void,
  userForwarding: ?CallForwardingT
|};

export type PropsT = OwnPropsT;
let requestDestinationsCancelTokenSource;

export const PresenceCallForwarding = ({
  enterpriseId,
  forwardingTarget,
  updateForwardingTarget,
  userForwarding
}: PropsT): Element<'div' | typeof CenterHorizontally> => {
  const { t } = useTranslation();
  const [searchResults, setSearchResults] = useState<DirectoryFwdT[]>([]);
  const dispatch = useDispatch();
  const [searchKey, setSearchKey] = useState(0);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const forwardingTargets = ['INVALID', 'EXTERNAL', 'ENT_VM', 'ENT_RCPT', 'REJECTION', 'INTERNAL'];
  const [errorMsg, setErrorMsg] = useState();

  const fetchDirectories = async searchText => {
    if (requestDestinationsCancelTokenSource) {
      requestDestinationsCancelTokenSource.cancel();
    }
    requestDestinationsCancelTokenSource = CancelToken.source();
    const results = await dispatch(
      getAllForwardingTargets(enterpriseId, requestDestinationsCancelTokenSource.token, {
        search: searchText
      })
    );
    if (results) {
      setSearchResults(results);
    }
  };

  useEffect(() => {
    requestDestinationsCancelTokenSource = CancelToken.source();
    const fetchTargets = async () => {
      setShowLoadingSpinner(true);
      await fetchDirectories('');
      setShowLoadingSpinner(false);
      setSearchKey(new Date().getTime());
    };
    fetchTargets();
    return () => {
      requestDestinationsCancelTokenSource.cancel();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return showLoadingSpinner ? (
    <CenterHorizontally>
      <LoadingSpinner />
    </CenterHorizontally>
  ) : (
    <div className={styles['forwarding-selection']}>
      <div className={styles.title}>{t('presenceCalendarListing.callForwardingTitle')}</div>
      {userForwarding ? (
        <div>{userForwarding.label}</div>
      ) : (
        <div>
          <div>{t('presenceCalendarListing.callForwardingInfo')}</div>
          <Dropdown
            id="target-selection"
            name="target-selection"
            items={forwardingTargets.map(trgt => ({
              label: t(`forwardingTargets.${trgt}`),
              value: trgt
            }))}
            light
            className={styles['target-selection']}
            selectedValue={forwardingTarget.type}
            onValueChange={element => {
              setSearchResults([]);
              updateForwardingTarget({ type: element.dataset.value, value: '' });
            }}
          />
          {forwardingTarget.type === 'INTERNAL' && (
            <Search
              key={searchKey}
              id="forwarding_search_internal"
              className={styles['search-area']}
              items={searchResults}
              selectedValue={forwardingTarget.value}
              i18n_search_helpText={t('callflows.acdForwardingField.transferInternalInfo')}
              i18n_search_errorMessage={t('callflows.acdForwardingField.transferInternalError')}
              onSearch={value => {
                updateForwardingTarget({
                  type: 'INTERNAL',
                  value: ''
                });
                fetchDirectories(value);
              }}
              customSearchIcon={<IconSearchRegular />}
              onValueChange={element => {
                updateForwardingTarget({
                  type: 'INTERNAL',
                  value: element.dataset.value
                });
              }}
            />
          )}
          {forwardingTarget.type === 'EXTERNAL' && (
            <Input
              id="external-target-input"
              onValueChange={e => {
                if (fieldValidators.phoneNumberValidator(e.currentTarget.value) !== undefined) {
                  setErrorMsg(t('generic.validators.phone'));
                } else {
                  setErrorMsg('');
                }
                updateForwardingTarget({ type: 'EXTERNAL', value: e.currentTarget.value });
              }}
              className={styles['external-target']}
              minlength={3}
              maxlength={14}
              i18n_input_errorMessage={errorMsg}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default PresenceCallForwarding;

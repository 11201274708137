// @flow
import React, { type Element, useEffect, useState } from 'react';
import Input from '@design-system/component-library/src/components/Input';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { CancelToken } from 'axios';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Search from '@design-system/component-library/src/components/Search';
import IconSearchRegular from '@design-system/component-library/src/components/Icon/lib/IconSearchRegular';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import { getAllForwardingTargets } from '../../../../../../ducks/entities/directory/directoryOperations';
import type { DirectoryFwdT } from '../../../../../../ducks/entities/directory/directoryOperations';
import CenterHorizontally from '../../../../../../components/CenterHorizontally/CenterHorizontally';
import styles from './SpeedDialForwardingField.module.scss';

export type PropsT = {|
  externalField: string,
  internalField: string,
  title: string,
  filterSearchResults?: DirectoryFwdT => boolean,
  description?: string,
  // eslint-disable-next-line react/no-unused-prop-types
  disabled?: boolean,
  containerStyle?: string
|};

let requestDestinationsCancelTokenSource;

export const SpeedDialForwardingField = (
  props: PropsT
): Element<'div' | typeof CenterHorizontally> => {
  const {
    externalField,
    internalField,
    title,
    description,
    filterSearchResults,
    containerStyle
  } = props;
  const [searchResults, setSearchResults] = useState<DirectoryFwdT[]>([]);

  // form
  const {
    setValue,
    watch,
    formState: { errors }
  } = useFormContext();
  const values = watch();
  const { t } = useTranslation();
  const currentUser = useSelector(state => state.currentUser);
  const dispatch = useDispatch();
  const [searchKey, setSearchKey] = useState(0);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);

  const fetchDirectories = async searchText => {
    if (currentUser.currentEnterprise) {
      if (requestDestinationsCancelTokenSource) {
        requestDestinationsCancelTokenSource.cancel();
      }
      requestDestinationsCancelTokenSource = CancelToken.source();
      const results = await dispatch(
        getAllForwardingTargets(
          currentUser.currentEnterprise.id,
          requestDestinationsCancelTokenSource.token,
          {
            search: searchText
          }
        )
      );
      if (results) {
        const filteredResults =
          filterSearchResults && results
            ? results.filter(result => (filterSearchResults ? filterSearchResults(result) : true))
            : results;
        setSearchResults(filteredResults);
      }
    }
  };

  useEffect(() => {
    requestDestinationsCancelTokenSource = CancelToken.source();
    const fetchTargets = async () => {
      setShowLoadingSpinner(true);
      await fetchDirectories(values[internalField].value);
      setShowLoadingSpinner(false);
      setSearchKey(new Date().getTime());
    };
    fetchTargets();

    return () => {
      requestDestinationsCancelTokenSource.cancel();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const descriptionMsg: string =
    description === undefined ? t('callflows.forwardingField.description') : description;

  const numberInputField = (
    <div className={styles.input}>
      <div className={styles['input-wrapper']} data-cy={`number-input-${externalField}`}>
        <Input
          id="external-number-input"
          data-cy="external-number-input"
          className={styles['external-number-input']}
          onValueChange={event => {
            setSearchResults([]);
            setValue(
              internalField,
              {
                ...values[internalField],
                value: ''
              },
              {
                shouldValidate: true,
                shouldDirty: true
              }
            );
            setSearchKey(new Date().getTime());
            setValue(
              externalField,
              {
                ...values[externalField],
                value: event.target.value
              },
              {
                shouldValidate: true,
                shouldDirty: true
              }
            );
          }}
          optional
          i18n_input_optionalText=""
          defaultValue={values[externalField].value}
          maxlength={20}
          touched
          i18n_input_errorMessage={
            errors[externalField] && (errors[externalField].message || 'error')
          }
          placeholder={t('callflows.forwardingField.longNumberPlaceholder')}
        />
      </div>
    </div>
  );

  return showLoadingSpinner ? (
    <CenterHorizontally>
      <LoadingSpinner />
    </CenterHorizontally>
  ) : (
    <div
      className={classnames(styles['field-group-section'], containerStyle)}
      data-cy={`forwarding-field-${internalField}`}
    >
      <div className={styles['field-section']}>
        <div className={styles.title}>{title}</div>
        {descriptionMsg && <div className={styles.description}>{descriptionMsg}</div>}
      </div>
      <div className={styles['forwarding-selection']}>
        <div className={styles['wide-detail']}>
          <div role="group" data-cy={`acd-forwarding-radio-button-${internalField}`}>
            {numberInputField}
            <div className={styles.separator}>{t('forwardingSummary.twoPart.or')}</div>
            <div className={styles.title}>
              {t('callflows.acdForwardingField.transferInternalInfo')}
            </div>
            <div className={styles['input-wrapper']}>
              <Search
                key={searchKey}
                id="forwarding_search_internal"
                className={styles['search-area']}
                items={searchResults}
                optional
                i18n_search_optionalText=""
                selectedValue={values[internalField].value}
                i18n_search_helpText={t('callflows.acdForwardingField.transferInternalInfo')}
                i18n_search_errorMessage={t('callflows.acdForwardingField.transferInternalError')}
                onSearch={value => {
                  setValue(
                    externalField,
                    {
                      ...values[externalField],
                      value: ''
                    },
                    {
                      shouldValidate: true,
                      shouldDirty: true
                    }
                  );
                  fetchDirectories(value);
                }}
                customSearchIcon={<IconSearchRegular />}
                onValueChange={element => {
                  setValue(
                    internalField,
                    {
                      ...values[internalField],
                      value: element.dataset.value
                    },
                    {
                      shouldValidate: true,
                      shouldDirty: true
                    }
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpeedDialForwardingField;

// @flow
import React, { type Element, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import Input from '@design-system/component-library/src/components/Input';
import type { UserStateEntityT } from '../../../../../ducks/entities/user/userTypes';
import ForwardingTarget from '../../components/ForwardingTarget';
import { selectors as userSelectors } from '../../../../../ducks/entities/user';
import type { FieldT } from '../../../../../ducks/entities/callForwarding/callForwardingTypes';
import styles from './ForwardingTargetSelector.module.scss';

export type PropsT = {|
  field: string,
  enterpriseId: string,
  shouldValidate?: boolean,
  isEnterpriseForwarding: boolean,
  userId: string,
  userIsLoading: boolean
|};

export const ForwardingTargetSelector = (props: PropsT): Element<'div'> => {
  const {
    field,
    enterpriseId,
    isEnterpriseForwarding,
    userId,
    userIsLoading,
    shouldValidate
  } = props;

  const { t } = useTranslation();
  const enterpriseUsers: UserStateEntityT[] = useSelector(state =>
    userSelectors.byEnterpriseId(state, enterpriseId || '')
  );
  const currentUser = !R.isEmpty(enterpriseUsers)
    ? enterpriseUsers.find(user => user.id === userId)
    : null;
  const substituteList = currentUser && currentUser.substitutes ? currentUser.substitutes : [];
  const [errorMessage, setErrorMessage] = useState('');
  const MIN_DURATION = 15;
  const MAX_DURATION = 115;

  // form
  const {
    setValue,
    watch,
    formState: { errors },
    setError
  } = useFormContext();

  const values = watch();
  const value: FieldT = values[field];
  const validateValue = newValue => {
    if (
      newValue &&
      (parseInt(newValue, 10) < MIN_DURATION || parseInt(newValue, 10) > MAX_DURATION)
    ) {
      setErrorMessage(t('integerField.error', { min: MIN_DURATION, max: MAX_DURATION }));
      setError(field);
    } else {
      setValue(
        field,
        { ...value, noAnswerForwardingDelay: newValue * 1000 },
        {
          shouldValidate: shouldValidate === undefined ? true : shouldValidate,
          shouldDirty: true
        }
      );
      setErrorMessage('');
    }
  };

  return (
    <div id="forwarding-destination" className={styles['destination-area']}>
      {values.forwardingType === 'NO_ANSWER' && (
        <Input
          name={field}
          className={styles['input-container']}
          data-cy="input"
          onValueChange={e => {
            validateValue(e.target.value);
          }}
          defaultValue={value.noAnswerForwardingDelay / 1000}
          maxlength={3}
          touched
          label={t('forwardingDetails.reasonNoAnswerPrefix')}
          i18n_input_errorMessage={errorMessage}
          i18n_input_helpText={t('forwardingDetails.reasonNoAnswerSuffix')}
        />
      )}
      {values.forwardingType === 'ON_BUSY' && (
        <span className={styles['description-text']}>
          {t('forwardings.reasons.onBusy')}
          {t('forwardings.reasons.moveTo')}
        </span>
      )}
      {values.forwardingType === 'UNREACHABLE' && (
        <span className={styles['description-text']}>
          {t('forwardings.reasons.unreachable')}
          {t('forwardings.reasons.moveTo')}
        </span>
      )}
      {(values.forwardingType === 'PRESENCE' || values.forwardingType === 'ALWAYS') && (
        <span className={styles['description-text']}>{t('forwardings.reasons.moveWhere')}</span>
      )}
      <div className={styles.target}>
        <ForwardingTarget
          field={field}
          enterpriseId={enterpriseId}
          // $FlowFixMe
          substitutes={substituteList}
          errorMessage={errors[field] ? errors[field].message : ''}
          enterpriseRule={!!isEnterpriseForwarding}
          userIsLoading={userIsLoading}
        />
      </div>
    </div>
  );
};

export default ForwardingTargetSelector;
